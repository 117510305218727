var columns = [{
  title: "ID",
  dataIndex: "id",
  key: "id",
  // width: '11%',
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "用户昵称",
  dataIndex: "username",
  key: "username",
  // width: '11%',
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: "uid",
  dataIndex: "uid",
  key: "uid",
  // width: '11%',
  scopedSlots: {
    customRender: "uid"
  }
}, {
  // title: '邮箱',
  dataIndex: "email",
  key: "email",
  // width: '15%',
  scopedSlots: {
    customRender: "email"
  },
  slots: {
    title: "emailTit"
  }
},
// {
//     title: '邀请人',
//     dataIndex: 'invitationname',
//     key: 'invitationname',
//     // width: '11%',
//     scopedSlots: {
//         customRender: 'invitationname'
//     }
// },

{
  title: "用户等级",
  dataIndex: "usergrade",
  key: "usergrade",
  // width: '11%',
  scopedSlots: {
    customRender: "usergrade"
  }
}, {
  title: "注册时间(GMT+8)",
  dataIndex: "registertime",
  key: "registertime",
  // width: '15%',
  scopedSlots: {
    customRender: "registertime"
  }
}, {
  title: "状态",
  dataIndex: "status",
  key: "status",
  // width: '9%',
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "CBK余额",
  dataIndex: "cbkNum",
  key: "cbkNum",
  // width: '9%',
  scopedSlots: {
    customRender: "cbkNum"
  }
},
// {
//     title: '鉴定币余额',
//     dataIndex: 'goldcoins',
//     key: 'goldcoins',
//     // width: '9%',
//     scopedSlots: {
//         customRender: 'goldcoins'
//     }
// },
{
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 240,
  scopedSlots: {
    customRender: "action"
  }
}];
var friendsColumns = [{
  title: "邀请ID",
  dataIndex: "id",
  key: "id",
  // width: '11%',
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "邀请口令",
  dataIndex: "inviteCode",
  key: "invitecode",
  // width: '11%',
  scopedSlots: {
    customRender: "invitecode"
  }
}, {
  title: "邀请人",
  dataIndex: "inviteusername",
  key: "inviteusername",
  // width: '11%',
  scopedSlots: {
    customRender: "inviteusername"
  }
}, {
  title: "被邀请人",
  dataIndex: "username",
  key: "username",
  // width: '11%',
  scopedSlots: {
    customRender: "username"
  }
}, {
  title: "被邀请人邮箱",
  dataIndex: "email",
  key: "email",
  // width: '11%',
  scopedSlots: {
    customRender: "email"
  }
}, {
  title: "被邀请人注册时间(GMT+8)",
  dataIndex: "invitetime",
  key: "invitetime",
  // width: '11%',
  scopedSlots: {
    customRender: "invitetime"
  }
}, {
  title: "被邀请人-设备ID",
  dataIndex: "deviceid",
  key: "deviceid",
  // width: '11%',
  scopedSlots: {
    customRender: "deviceid"
  }
}, {
  title: "被邀请人-IP",
  dataIndex: "ipaddress",
  key: "ipaddress",
  // width: '11%',
  scopedSlots: {
    customRender: "ipaddress"
  }
}, {
  title: "被邀请人-设备端",
  dataIndex: "os",
  key: "os",
  // width: '11%',
  scopedSlots: {
    customRender: "os"
  }
}, {
  title: "被邀请人-设备端Model",
  dataIndex: "model",
  key: "model",
  // width: '11%',
  scopedSlots: {
    customRender: "model"
  }
}, {
  title: "被邀请人-系统版本",
  dataIndex: "edition",
  key: "edition",
  // width: '11%',
  scopedSlots: {
    customRender: "edition"
  }
}, {
  title: "被邀请人-当前系统版本",
  dataIndex: "version",
  key: "version",
  // width: '11%',
  scopedSlots: {
    customRender: "version"
  }
}, {
  title: "被邀请人-是否破解设备",
  dataIndex: "prisonbreak",
  key: "prisonbreak",
  scopedSlots: {
    customRender: "prisonbreak"
  }
}, {
  title: "CBK发放有效性",
  dataIndex: "cbkvalidity",
  key: "cbkvalidity",
  scopedSlots: {
    customRender: "cbkvalidity"
  }
}];

// 备注日志列
var remarkLogColumns = [{
  title: "备注ID",
  dataIndex: "id",
  key: "id",
  // width: '11%',
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "备注文本",
  dataIndex: "notes",
  key: "notes",
  // width: '11%',
  scopedSlots: {
    customRender: "notes"
  }
}, {
  title: "备注人",
  dataIndex: "createby",
  key: "createby",
  // width: '11%',
  scopedSlots: {
    customRender: "createby"
  }
}, {
  title: "备注时间(GMT+8)",
  dataIndex: "createtime",
  key: "createtime",
  // width: '11%',
  scopedSlots: {
    customRender: "createtime"
  }
}];

// 操作日志列
var operateLogColumns = [{
  title: "日志ID",
  dataIndex: "id",
  key: "id",
  // width: '11%',
  scopedSlots: {
    customRender: "id"
  }
}, {
  title: "操作",
  dataIndex: "operation",
  key: "operation",
  // width: '11%',
  scopedSlots: {
    customRender: "operation"
  }
}, {
  title: "操作人",
  dataIndex: "createby",
  key: "createby",
  // width: '11%',
  scopedSlots: {
    customRender: "createby"
  }
}, {
  title: "操作时间(GMT+8)",
  dataIndex: "createtime",
  key: "createtime",
  // width: '11%',
  scopedSlots: {
    customRender: "createtime"
  }
}];
export { columns, friendsColumns, remarkLogColumns, operateLogColumns };