var searchKeys = [{
  key: "id",
  label: "UID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: 'username',
  label: '用户昵称',
  placeholder: "请输入",
  input: true
}, {
  key: "email",
  label: "邮箱",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: 'dates',
  label: '注册时间(GMT+8)',
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "结束时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "deviceid",
  label: "最近一次登录设备ID",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "ipaddress",
  label: "最近一次登录IP",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "type",
  label: '账号状态',
  placeholder: '请选择',
  required: false,
  rules: [],
  select: true,
  attrs: {
    allowClear: true
  }
}, {
  key: "cbkgrant",
  label: 'CBK发放状态',
  placeholder: '请选择',
  required: false,
  rules: [],
  select: true,
  attrs: {
    allowClear: true
  }
}, {
  key: "cbkfreeze",
  label: 'CBK余额状态',
  placeholder: '请选择',
  required: false,
  rules: [],
  select: true,
  attrs: {
    allowClear: true
  }
}, {
  key: "usergrade",
  label: '用户等级',
  placeholder: '请选择',
  required: false,
  rules: [],
  select: true,
  attrs: {
    allowClear: true
  }
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };